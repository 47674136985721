







































































import { Empresa } from '@/core/models/geral';
import { OrdemDestinacao, OrdemServicoResiduo } from '@/core/models/residuo';
import { EmpresaService } from '@/core/services/geral';
import { OrdemDestinacaoService } from '@/core/services/residuo';
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class DetalharOrdemServico extends Vue {

item: OrdemDestinacao = new OrdemDestinacao();
sessionApp: any;
parametro: any;
overlay: boolean = false;

  mounted(){

    const id: number = Number(this.$route.params.id);
    this.overlay = true;
    const service = new OrdemDestinacaoService();
    service.ObterPorId(id, 'Receptor, Residuos.OrdemServico.Contrato.Cliente, Residuos.Unidade, Residuos.Residuo, Transportadora, Veiculo, Motorista')
        .then(
            res => {
              this.item = res.data;
            },
            err => this.$swal('Aviso', err.message, 'error')
        ).finally(() => {
          this.overlay = false;
        });
  }

  Imprimir(){
      window.print();
  }

  Fechar(){
      window.close();
  }

}

